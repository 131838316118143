import React from "react"
import Container from "../components/container"
import SEO from "../components/seo"
import {graphql, Link} from "gatsby";
import {Typography, Row, Col, Card, Button} from "antd";
import {GatsbyImage, getImage}  from "gatsby-plugin-image";
import Meta from "antd/lib/card/Meta";

export const query = graphql`
    query{
        directus {
            news {
                headline
                status
                text
                date_created
                image{
                    id
                    imageFile{
                        childImageSharp {
                            gatsbyImageData(
                                width: 600
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, AVIF]
                            )
                        }
                    }
                }
                slug
            }
        }

    }`
const News = ({data}) => (
    <Container defKey="1">
        <SEO title="News"/>
        <div style={{padding: "1rem"}}>
            <Row gutter={20}>
                {data.directus.news.sort((a, b) => new Date(b.date_created) - new Date(a.date_created)).map((node) => {
                    return <Col xs={24} md={8}>
                        <Link to={"/news/" + node.slug}>
                            <Card hoverable extra={new Date(node.date_created).toLocaleDateString()} cover={node.image && <GatsbyImage image={getImage(node.image.imageFile)} alt={node.headline} />
                            }
                                  title={node.headline}>
                                <Typography.Paragraph ellipsis={{rows: 2, expandable: false, symbol: 'more'}}>
                                    <div dangerouslySetInnerHTML={{__html: node.text}}/>
                                </Typography.Paragraph>
                                <Button> Weiterlesen</Button>


                            </Card>
                        </Link>
                    </Col>
                })}
            </Row>
        </div>
    </Container>
)

export default News

